// This icon file is generated automatically.

import { IconDefinition } from '../types';

const CryptoFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 20 20',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M10 4.9a5.1 5.1 0 1 0 0 10.2 5.1 5.1 0 0 0 0-10.2m1.79 5.53-1.36 1.36a.6.6 0 0 1-.86 0l-1.36-1.36a.6.6 0 0 1 0-.86l1.36-1.36a.6.6 0 0 1 .86 0l1.36 1.36a.6.6 0 0 1 0 .86M7.42 2.23a8 8 0 0 1 5.17 0c.6.2 1.34.6 1.87.94a1.84 1.84 0 1 0 .7-.9 11 11 0 0 0-2.22-1.1c-1.88-.64-4-.64-5.88 0a.56.56 0 1 0 .36 1.06M12.58 17.77a8.2 8.2 0 0 1-7.05-.92 1.85 1.85 0 1 0-.71.88 9.17 9.17 0 0 0 8.13 1.1.56.56 0 1 0-.37-1.06M19.31 10q0-1.52-.48-2.94a.56.56 0 1 0-1.06.36 8 8 0 0 1 0 5.17c-.18.54-.54 1.3-.88 1.89a1.85 1.85 0 1 0 .87.74c.4-.67.85-1.61 1.07-2.28q.48-1.42.48-2.94M2.23 12.58a8 8 0 0 1 0-5.17c.17-.5.56-1.28.92-1.88a1.84 1.84 0 1 0-.89-.72 9.15 9.15 0 0 0-1.1 8.13.56.56 0 1 0 1.07-.36',
        },
      },
    ],
  },
  name: 'crypto',
  theme: 'filled',
};

export default CryptoFilled;
